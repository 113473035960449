#footer .footer1 {
    background-color: #093D7A;
}

#footer .footer1 .container {
    padding: 2rem 0;
}

#footer .footer1 .container figure img {
    width: 15%;
}

#footer .footer2 {
    background-color: #093D7A;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    border-top: 1px solid #0058AA;
    color: white;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container figure img {
        width: 20%;
    }

    #footer .footer2 .container {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container {
        padding: 1rem 0;
    }

    #footer .footer1 .container figure img {
        width: 30%;
    }
}