#ctaWpp {
    background-color: #0CC76D;
    font-weight: 700;
    padding: .7rem 1rem;
    border-radius: 10rem;
    width: 50%;
}

#ctaWpp a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
}

#ctaWpp a p {
    font-size: 18px;

}

#ctaWpp a figure {
    width: 9%;
    margin-top: .3rem;
}

#ctaWpp a figure img {
    width: 100%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWpp a figure {
        width: 10%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}