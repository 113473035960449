#about .about1 {
  background: url("../../assets/about1.png") no-repeat center center;
  background-size: cover;
  min-height: 80vh;
}

#about .about1 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about1 .container .title {
  color: #FFF500;
  text-align: left;
  width: 80%;
}

#about .about1 .container .text {
  font-size: 25px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: white;
  width: 70%;
}

#about .about1 .container #ctaWpp {
  width: 25%;
}

#mobile {
  display: none;
}

#about .about2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about2 .container .text {
  text-align: center;
  color: #4A5E69;
  font-size: 25px;
  font-weight: 800;
  width: 80%;
  margin: 0 auto;
}

#about .about2 .container figure {
  margin-top: 2rem;
}

#about .about2 .container figure img {
  width: 70%;
}

#about .about3 {
  background-color: #ECF8FF;
}

#about .about3 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about3 .container .text {
  text-align: center;
  color: #4A5E69;
  font-size: 25px;
  font-weight: 800;
  margin-top: -1rem;
}

#about .about3 .container #ctaWpp {
  margin: 0 auto;
  width: 20%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 {
    background: url("../../assets/mobile/about1.png") no-repeat center center;
    background-size: cover;
    min-height: 0;
    height: 30vh;
  }

  #about .about1 .container {
    height: 100%;
    justify-content: flex-end;
  }

  #about .about1 .container .title {
    color: #FFF500;
    text-align: left;
    width: 75%;
  }

  #about .about1 .container .text {
    display: none;
  }

  #about .about1 .container #ctaWpp {
    display: none;
  }

  #mobile {
    display: block;
    background-color: #094B87;
  }

  #mobile .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  #mobile .container .text {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: white;
    text-align: center;
  }

  #mobile .container #ctaWpp {
    width: 45%;
    margin: 0 auto;
  }

  #about .about2 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  #about .about2 .container .text {
    font-size: 20px;
    width: 90%;
  }

  #about .about2 .container figure img {
    width: 50%;
  }

  #about .about3 .container .text {
    font-size: 20px;
  }

  #about .about3 .container #ctaWpp {
    width: 35%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 {
    height: 20vh;
  }

  #mobile .container .text {
    font-size: 14px;
  }

  #mobile .container #ctaWpp {
    width: 80%;
  }

  #about .about2 .container .text {
    font-size: 14px;
    width: 100%;
    margin-bottom: -1rem;
  }

  #about .about3 .container .text {
    font-size: 14px;
  }

  #about .about3 .container #ctaWpp {
    width: 70%;
  }
}