#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header1 .container .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -3rem;
}

#header .header1 .container .top .left {
    width: 20%;
    text-align: left;
}

#header .header1 .container .top .left img {
    width: 70%;
}

#header .header1 .container .top #ctaWpp {
    width: 20%;
}

#header .header1 .container .title {
    text-align: left;
    color: white;
    width: 60%;
    display: flex;
    flex-direction: column;
}

#header .header1 .container .title span {
    color: #FFF500;
}

#header .header1 .container .text {
    font-size: 30px;
    color: white;
}

#header .header1 .container #ctaWpp {
    width: 30%;
}

#header .header2 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header2 .container .gallery {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

#header .header2 .container .gallery .item {
    background-color: #ECF8FF;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 7px;
    padding-right: 2rem;
    width: 100%;
    height: 100%;
}

#header .header2 .container .gallery .item .left {
    width: 100%;
    height: 100%;
}

#header .header2 .container .gallery .item .right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    height: 100%;
}

#header .header2 .container .gallery .item .right .title {
    color: #0088CC;
    text-align: left;
    font-size: 25px;
}

#header .header2 .container .gallery .item .right .text {
    font-size: 18px;
    color: #4A5E69;
}

#header .header2 .container .gallery .item:first-child .right #ctaWpp {
    width: 110%;
    margin-top: 2rem;
}

#header .header2 .container .gallery .item .right #ctaWpp {
    width: 110%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center 0;
        background-size: cover;
        min-height: 60vh;
    }

    #header .header1 .container {
        height: 100%;
        justify-content: flex-end;
    }

    #header .header1 .container .top {
        display: none;
    }

    #header .header1 .container .title {
        width: 100%;
        text-align: center;
    }

    #header .header1 .container .title span {
        color: #FFF500;
    }

    #header .header1 .container .text {
        font-size: 20px;
        text-align: center;
    }

    #header .header1 .container #ctaWpp {
        margin: 0 auto;
        width: 50%;
    }

    #header .header2 .container .gallery {
        flex-direction: column;
    }

    #header .header2 .container .gallery .item {
        flex-direction: column;
        padding-right: 0;
        padding-bottom: 2rem;
    }

    #header .header2 .container .gallery .item .left {
        width: 100%;
    }

    #header .header2 .container .gallery .item .left img {
        aspect-ratio: 1;
    }

    #header .header2 .container .gallery .item .right {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        height: 100%;
        padding: 0 2rem;
        text-align: center;
    }

    #header .header2 .container .gallery .item .right .title {
        text-align: center;
        font-size: 20px;
    }

    #header .header2 .container .gallery .item .right .text {
        font-size: 14px;
    }

    #header .header2 .container .gallery .item:first-child .right #ctaWpp {
        width: 45%;
        margin: 0 auto;
    }

    #header .header2 .container .gallery .item .right #ctaWpp {
        width: 45%;
        margin: 0 auto;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 .container #ctaWpp {
        width: 80%;
    }

    #header .header2 .container .gallery .item:first-child .right #ctaWpp {
        width: 95%;
    }

    #header .header2 .container .gallery .item .right #ctaWpp {
        width: 95%;
    }
}